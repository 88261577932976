import React from "react"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Row, Col, Container, ListGroup, Carousel } from "react-bootstrap"


class NotFound extends React.Component {
  constructor(props) {
    super(props)
  }

  render(){
    return(
      <Layout siteInfo={this.props.data.prismicSiteinfo.data} menu={this.props.data.allPrismicMenu.nodes}>
        <SEO title="404: Not found" />
        <Row className="unhistoried-index-slideshow-row">
          <Col md={2}></Col>
          <Col md={8} className="unhistoried-index-slideshow-col">
            <h1>404 NOT FOUND</h1>
            <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
          </Col>
          <Col md={2}></Col>
        </Row>
      </Layout>
    )
  }
}

export default NotFound

export const notFoundQuery = graphql`
query Notfound {
  prismicSiteinfo {
    data {
      contact
      website_name {
        text
      }
      banner_message {
        text
      }
      about_me {
        text
      }
    }
  }
  allPrismicMenu {
    nodes {
      data {
        menu_link
        menu_title
      }
    }
  }
}
`
